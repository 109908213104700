


















import Vue from "vue";
export default Vue.extend({
  props: {
    location: {
      type: Object,
    },
    strokeColor: {
      type: String,
      default: "#FFF",
    },
    tabindex: {
      type: Number,
    },
    isLocationSelected: {
      type: Boolean,
    },
  },
  computed: {
    fillColor(): string {
      return this.isLocationSelected ? "#FFFFFF" : "#009869";
    },
  },
});
