


























import Vue from "vue";
import svgPath from "./svg-path.vue";

export default Vue.extend({
  components: {
    svgPath,
  },
  props: {
    map: {
      type: Object,
      required: true,
      validator(map: {
        viewBox: string;
        locations: { path: string; id: string }[];
      }) {
        return (
          typeof map.viewBox === "string" &&
          Array.isArray(map.locations) &&
          map.locations.every(
            (location: { path: string; id: string }) =>
              typeof location.path === "string" &&
              typeof location.id === "string"
          )
        );
      },
    },
    active: {
      type: String,
    },
    select: {
      type: String,
    },
    locationClass: {
      type: [String, Function],
      default: null,
    },
    locationFill: {
      type: [String, Function],
      default: null,
    },
    locationTabindex: {
      type: [String, Function],
      default: null,
    },
    isLocationSelected: {
      type: Function,
      default: null,
    },
  },
  methods: {
    onClick() {
      alert(1);
    },
  },
});
