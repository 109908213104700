

























































































































































































































































































































































































































































































































































import Vue from "vue";
import Cookies from "js-cookie";
import svgMap from "./components/svg-map.vue";
import japan from "./json/japan.json";
const lomaPrefs = [
  "Hokkaido",
  "Aomori",
  "Iwate",
  "Miyagi",
  "Akita",
  "Yamagata",
  "Fukushima",
  "Ibaraki",
  "Tochigi",
  "Gunma",
  "Saitama",
  "Chiba",
  "Tokyo",
  "Kanagawa",
  "Niigata",
  "Toyama",
  "Ishikawa",
  "Fukui",
  "Yamanashi",
  "Nagano",
  "Gifu",
  "Shizuoka",
  "Aichi",
  "Mie",
  "Shiga",
  "Kyoto",
  "Osaka",
  "Hyogo",
  "Nara",
  "Wakayama",
  "Tottori",
  "Shimane",
  "Okayama",
  "Hiroshima",
  "Yamaguchi",
  "Tokushima",
  "Kagawa",
  "Ehime",
  "Kochi",
  "Fukuoka",
  "Saga",
  "Nagasaki",
  "Kumamoto",
  "Oita",
  "Miyazaki",
  "Kagoshima",
  "Okinawa",
];

const urls: string[] = [
  "/",
  "/hokkaido",
  "/aomori",
  "/iwate",
  "/miyagi",
  "/akita",
  "/yamagata",
  "/fukushima",
  "/ibaraki",
  "/tochigi",
  "/gunma",
  "/saitama",
  "/chiba",
  "/tokyo",
  "/kanagawa",
  "/niigata",
  "/toyama",
  "/ishikawa",
  "/fukui",
  "/yamanashi",
  "/nagano",
  "/gifu",
  "/shizuoka",
  "/aichi",
  "/mie",
  "/shiga",
  "/kyoto",
  "/osaka",
  "/hyogo",
  "/nara",
  "/wakayama",
  "/tottori",
  "/shimane",
  "/okayama",
  "/hiroshima",
  "/yamaguchi",
  "/tokushima",
  "/kagawa",
  "/ehime",
  "/kochi",
  "/fukuoka",
  "/saga",
  "/nagasaki",
  "/kumamoto",
  "/oita",
  "/miyazaki",
  "/kagoshima",
  "/okinawa",
];

type State = {
  lomaPrefs: string[];
  japan: any;
  pref: number;
  active: number;
  latitude?: number;
  longitude?: number;
};
export default Vue.extend({
  components: {
    svgMap,
  },
  data(): State {
    const cookiePref = Cookies.get("jtown-pref");
    return {
      lomaPrefs,
      japan,
      active: 0,
      latitude: undefined,
      longitude: undefined,
      pref: cookiePref !== undefined ? Number(cookiePref) : 0,
    };
  },
  computed: {
    areaUrl(): string {
      const pref = lomaPrefs[this.pref - 1].toLowerCase();
      return `/assets/area/img_area_map/img_area_${pref}.svg`;
    },
  },
  methods: {
    setPref(pref: number) {
      this.pref = pref;
    },
    handleSelect(location: { path: string; name: string }) {
      const index = lomaPrefs.findIndex((pref) => {
        return pref === location.name;
      });
      this.pref = index + 1;
    },
    handleActive(location: { path: string; name: string }) {
      const index = lomaPrefs.findIndex((pref) => {
        return pref === location.name;
      });
      this.active = index + 1;
    },
    prefHover(pref: number) {
      this.active = pref;
    },
    selected(pref: number) {
      return pref === this.pref
        ? "selected"
        : this.active === 0
        ? ""
        : this.active === pref
        ? "selected"
        : "";
    },
    goto() {
      Cookies.set("jtown-pref", String(this.pref), { expires: 28 });
      document.location.href = urls[this.pref];
    },
  },
});
